<template>
    <div :class="customClass" class="sosed-blaz">
        <b-carousel
            v-model="slide"
            indicators
        >
            <b-carousel-slide
                v-for="(item,index) in bannerArray"
                :key="index"
            >
                <img slot="img" class="d-block w-100" style="cursor: pointer"
                     :src="'/api/user/v1/image/'+item.bannerName" alt="image slot" v-on:click="carouselReroute(index)">
            </b-carousel-slide>
        </b-carousel>
    </div>
</template>

<script>
    import {BCarousel, BCarouselSlide } from 'bootstrap-vue'
    export default {
        props:{
            customClass:{
                Type: String
            }
        },
        components:{BCarousel, BCarouselSlide},
        data() {
            return {
                bannerArray:[],
                slide:0
            }
        },
        methods:{
            carouselReroute(index) {
                window.location.href = this.bannerArray[index].bannerLink
            },
            async loadData() {
                try {
                    const loadBanners = await this.$http.get('/api/user/v1/banner/')
                    this.bannerArray = loadBanners.data.banners
                } catch (error) {
                    this.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style scoped>
.sosed-blaz img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
    border-radius: 10px !important;
}
</style>